import React, { useState, useEffect, useRef } from 'react';
import api from '../../config/Api';
import { getSpinnerIcon, getSolidCheckCircleIcon, getSolidXIcon } from '../../helper/SvgHelper';


const PrintLabelCatalog = ({selectedRow,  setSelectedRow, setShowPrintLabelCatalog}) => {

    const responseMsgSectionRef = useRef(null);
    const printRef = useRef();

    const [loading, setLoading] = useState(false);

    const [responseStatus, setResponseStatus] = useState(null); 
    const [responseMsg, setResponseMsg] = useState(null);
    const [showResponseMsg, setShowResponseMsg] = useState(false);

    const [regTidRegRef, setRegTidRegRef] = useState([]);
    const [regNoOfSample, setRegNoOfSample] = useState([]);

    const [regTid, setRegTid] = useState(null);
    const [noOfSample, setNoOfSample] = useState(1);
    const [noOfCopies, setNoOfCopies] = useState(1);
    const [labelText, setLabelText] = useState(null);
    // const [labelWidth, setLabelWidth] = useState(null);
    // const [labelHeight, setLabelHeight] = useState(null);


    const ResponseMessageField = () => {
        return (
            <div ref={responseMsgSectionRef} className={`${responseStatus ? 'bg-green-50' : 'bg-red-50'} rounded-md p-4 mt-1`}>
                <div className="flex">
                    <div className="flex-shrink-0">
                        { responseStatus ? getSolidCheckCircleIcon() : getSolidXIcon() }
                    </div>
                    <div className="ml-3">
                        <p className={`${responseStatus ? 'text-green-800' : 'text-red-800'} text-sm font-medium`}>{responseMsg}</p>
                    </div>
                </div>
            </div>
        )
    }

    const handlePrintWithIframe = () => {
        const printContent = printRef.current.innerHTML;
        const iframe = document.createElement("iframe");

        document.body.appendChild(iframe);
        iframe.style.display = "none";
        iframe.contentDocument.write(`<html><head><title>Print</title></head><body>${printContent}</body></html>`);
        iframe.contentDocument.close();
        iframe.contentWindow.print();
        document.body.removeChild(iframe);
    };

    // npm install qrcode.react
    // import React, { useState } from "react";
    // import QRCode from "qrcode.react";

    // const QRCodeGenerator = () => {
    //   const [text, setText] = useState("https://example.com");

    //   return (
    //     <div>
    //       <h2>QR Code Generator</h2>
    //       <input
    //         type="text"
    //         value={text}
    //         onChange={(e) => setText(e.target.value)}
    //         placeholder="Enter text or URL"
    //       />
    //       <QRCode value={text} size={200} />
    //     </div>
    //   );
    // };

    // export default QRCodeGenerator;

    const getRegistrationLabelInfo = async () => {
        setLoading(true);
        setShowResponseMsg(false);
        setResponseStatus(null);
        setShowResponseMsg(null);
        await api.get('/lims/submission-reg-label-details', { params: { sampleSubmissionId: selectedRow.Id } })
                .then(res => {
                    if(res.data.success) { 
                        let details = res.data.data;
                        
                        setRegTidRegRef(details.regTidRegRef);                    
                        setRegNoOfSample(details.regNoOfSample);                    
                       
                        setResponseMsg(null);
                        setResponseStatus(true);
                        setShowResponseMsg(false);
                    };
                })
                .catch(err => {
                    if(err && err.response && err.response.data && err.response.data.message) {
                        setResponseMsg(JSON.stringify(err.response.data.message));
                    }
                    else {
                        setResponseMsg('Something went wrong, please contact IT Support.');
                    }
                    setResponseStatus(false);
                    setShowResponseMsg(true);
                });
        setLoading(false);
    }


    const updateRegistrationLabelInfo = async () => {
        if(labelText == regTidRegRef[regTid]) {
            setResponseMsg(labelText + ' Label Printed');
            setResponseStatus(true);
            setShowResponseMsg(true);
            return;
        }

        setLoading(true);
        setResponseMsg(null);
        setResponseStatus(null);
        setShowResponseMsg(false);

        await api.post('/lims/update-submission-reg-label', { 
            sampleSubmissionId: selectedRow.Id,
            regTid: regTid,
            labelText: labelText
        })
        .then(res => {
            if(res.data.success) { 
                let details = res.data.data;

                setRegTidRegRef(details.regTidRegRef); 

                setResponseMsg(res.data.message);
                setResponseStatus(true);
            };
        })
        .catch(err => {
            if (err && err.response && err.response.data && err.response.data.message) {
                setResponseMsg(JSON.stringify(err.response.data.message));
            }
            else {
                
                setResponseMsg('Something went wrong, please contact IT Support.');
            }
            setResponseStatus(false);
        })
        .finally(() => {
            setShowResponseMsg(true);
        });

        setLoading(false);
    }


    useEffect(() => {
        if(selectedRow) {
            getRegistrationLabelInfo();
        }
    }, []);

    useEffect(() => {
        setLabelText(regTid ? regTidRegRef[regTid] : null);
        setNoOfSample(regTid ? regNoOfSample[regTid] : 1);
    }, [regTid]);


    useEffect(() => {
        if(responseMsgSectionRef.current) { responseMsgSectionRef.current.scrollIntoView(); }
    }, [responseStatus, responseMsg, showResponseMsg]);

    return (
        <>
        <div className="hidden">
            <div ref={printRef} className="w-screen h-screen fixed left-0 top-0 flex items-center">
                <div className="mx-auto">
                {noOfCopies > 0 && Array.from({ length: noOfCopies }, (_, i) => i + 1).map((c, i) =>(
                    noOfSample > 0 && Array.from({ length: noOfSample }, (_, i) => i + 1).map((num, index) =>(
                        <div className="text-gray-900 text-center font-semibold" key={index+i}>{labelText + '-' + num}</div>
                    ))
                ))}
                </div>
            </div>
        </div>
        
        <div className="w-screen h-screen fixed left-0 top-0 bg-gray-800 bg-opacity-80 z-20 flex items-center">
            <div className="w-2/5 mx-auto">
                <div className="py-5 px-16 flex justify-center bg-gray-100 rounded-t-lg">
                    <div className="text-gray-900 text-center font-semibold w-1/3">
                        Label Print
                    </div>
                </div>
                
                <div className="shadow bg-white">
                    <div className="w-full">
                    {/* dialog content */}
                        <div className="px-10 w-full ">          
                            <div className="pb-5 grid grid-cols-6 gap-y-6 gap-x-4 overflow-y-auto">
                                <div className="col-span-6">
                                    {showResponseMsg && <ResponseMessageField />}
                                </div>

                                <div className="flex col-span-3 mx-2">
                                    <label className="block text-md font-medium text-gray-700 mt-1">
                                        Preview
                                    </label>
                                    <div className="w-5/6">
                                        <input
                                            disabled={true}
                                            value={labelText ? labelText+'-1' : ''}
                                            type="text"
                                            className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                        />
                                    </div>
                                </div>

                                <div className="col-span-3 mx-2">
                                    <div className="flex">
                                        <label className="block text-md font-medium text-gray-700 mt-1">
                                            Reg No.
                                        </label>
                                        <div className="w-3/4">
                                            <select className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                                value={regTid || ''}
                                                onChange={e => { setRegTid(e.target.value) }}>
                                                <option value=""></option>
                                                {regTidRegRef && Object.keys(regTidRegRef).map((regTid, index) =>(
                                                    <option className="text-gray-800" key={index} value={regTid}>{regTidRegRef[regTid]}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className="flex col-span-3 mx-2">
                                    <label className="block text-md font-medium text-gray-700 mt-1">
                                        Label Text
                                    </label>
                                    <div className="w-3/4">
                                        <input
                                            value={labelText || ''}
                                            onChange={e => { setLabelText(e.target.value); }}
                                            type="text"
                                            className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                        />
                                    </div>
                                </div>

                                <div className="flex col-span-3 mx-2">
                                    <label className="block text-md font-medium text-gray-700 mt-1">
                                        Copies
                                    </label>
                                    <div className="w-3/4">
                                        <input
                                            value={noOfCopies || 1}
                                            onChange={e => { setNoOfCopies(e.target.value); }}
                                            type="number"
                                            className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                        />
                                    </div>
                                </div>


                                {/* <div className="flex col-span-3 mx-2">
                                    <label className="block text-md font-medium text-gray-700 mt-1">
                                        Width(mm)
                                    </label>
                                    <div className="w-2/3">
                                        <input
                                            value={labelWidth || ''}
                                            onChange={e => { setLabelWidth(e.target.value); }}
                                            type="number"
                                            className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                        />
                                    </div>
                                </div>

                                <div className="flex col-span-3 mx-2">
                                    <label className="block text-md font-medium text-gray-700 mt-1">
                                        Height(mm)
                                    </label>
                                    <div className="w-2/3">
                                        <input
                                            value={labelHeight || ''}
                                            onChange={e => { setLabelHeight(e.target.value); }}
                                            type="number"
                                            className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                        />
                                    </div>
                                </div> */}

                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex rounded-b-lg shadow bg-gray-100 justify-between">
                    <div className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <button onClick={()=>{ setShowPrintLabelCatalog(false); setSelectedRow(null); }} type="button" className="items-center px-3 py-2.5 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                            Close
                        </button>      
                    </div>
      
                    <div className="px-6 py-3 text-xs font-medium text-gray-500">
                        <button
                            disabled={!labelText}
                            type="button"
                            className="bg-indigo-600 hover:bg-indigo-700 focus:outline-none px-3 py-2.5 shadow-sm text-sm leading-4 font-medium rounded-md text-white"
                            onClick={() => { handlePrintWithIframe(); updateRegistrationLabelInfo() }}
                        >
                            { loading ? <div className="px-6">{getSpinnerIcon()}</div> : 'Print' }
                        </button>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}

export default PrintLabelCatalog;